import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'


import pic01 from '../assets/images/pic01.webp'
import signature from '../assets/images/signature.webp'

const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>INKIII</title>
            <meta name="" content="Landing Page" />
        </Helmet>

        

        <div id="main">
           
            <section id="two" className="spotlights">
                <section>
                        <img src={pic01} alt="postcard" className="image" width="450" height="auto" margin-top=" 50px !important"/>
                    <div className="content">
                        <div className="inner">
                             <p>Featuring:</p><br/>
                                <h3>Quando sei qui con me</h3> 
                                <h3> by Fabiana Trudu</h3>
                            <p>"Close your eyes and picture the place you call home. I see Sardinia. I feel the warm salty breeze brushing against my skin, and the soothing sound of the waves fill me with freedom and nostalgia." -FT
                            </p>
                <br/>


                        </div>
                    </div>
                </section>
            </section>
        </div>

    </Layout>
)

export default Landing
